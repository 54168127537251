<template>
    <section class="app-ecommerce-details">
        <!-- Alert: No item found -->

        <b-overlay :show="formShow" rounded="sm" no-fade>
            <b-row>
                <b-col cols="10" :xl="events.length > 0 ? '10' : '12'" md="12">
                    <b-col cols="12">
                        <b-card no-body>
                            <b-card-body>
                                <b-row class="my-2">
                                    <b-col cols="2">
                                        <b-img-lazy rounded thumbnail fluid v-bind="mainProps" class="d-inline-block mr-1 mb-1" style="width: 100%" ref="refPreviewEl" :src="imageUrl" />
                                    </b-col>
                                    <b-col cols="10">
                                        <b-row class="justify-content-between">
                                            <h1 class="ml-1">{{ eventData.client }}</h1>
                                            <div>
                                                <b-button v-show="$Can('event_duplicate_btn')" class="mr-1" @click="duplicateEvent" variant="warning">
                                                    Duplicate
                                                </b-button>

                                                <b-button v-show="$Can('event_delete_btn')" class="mr-1" @click="deleteEvent" variant="danger">
                                                    Delete
                                                </b-button>

                                                <b-button v-show="$Can('event_edit_btn')" class="mr-1" @click="editEvent" variant="primary">
                                                    Edit
                                                </b-button>
                                            </div>
                                        </b-row>

                                        <table class="mt-2 table  table-bordered table-hover table-sm">
                                            <tbody>
                                                <tr>
                                                    <th class="w-25 bg-primary text-white" scope="row">
                                                        Client
                                                    </th>
                                                    <td class="w-75">
                                                        {{ eventData.client }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th scope="row" class="bg-primary text-white">
                                                        PO Number
                                                    </th>
                                                    <td>
                                                        {{ eventData.vendorReference }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th scope="row" class="bg-primary text-white">
                                                        Invoice No
                                                    </th>
                                                    <td>
                                                        {{ eventData.invoiceNo }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th scope="row" class="bg-primary text-white">
                                                        Date
                                                    </th>
                                                    <td>
                                                        {{ dateFormat(eventData.date) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="bg-primary text-white">
                                                        Status
                                                    </th>
                                                    <td>
                                                        <template>
                                                            <b-badge v-if="eventData.status == 'active'" pill :variant="`light-success`" class="text-capitalize">
                                                                Active
                                                            </b-badge>

                                                            <b-badge v-if="eventData.status == 'passive'" pill :variant="`light-warning`" class="text-capitalize">
                                                                Inactive
                                                            </b-badge>

                                                            <b-badge v-if="eventData.status == 'deleted'" pill :variant="`danger`" class="text-capitalize">
                                                                Deleted
                                                            </b-badge>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>

                        <b-card>
                            <b-tabs vertical nav-wrapper-class="nav-vertical" pills class="tab-class">
                                <b-tab active title="General Information" v-if="$Can('event_information_tab')">
                                    <b-card-body class="mt-0 pt-0">
                                        <b-row>
                                            <table class=" table  table-bordered table-hover   ">
                                                <tbody class="text-left">
                                                    <tr>
                                                        <td class="bg-secondary text-white w-25">Client</td>
                                                        <td class="w-75">{{ eventData.client }}</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-secondary text-white">PO Number</td>
                                                        <td>{{ eventData.vendorReference }}</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-secondary text-white">Invoice Number</td>
                                                        <td>{{ eventData.invoiceNo }}</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-secondary text-white">Location</td>
                                                        <td>{{ eventData.location }}</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-secondary text-white">Employees Required</td>
                                                        <td>{{ eventData.employeesRequired }}</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-secondary text-white">Employees Assigned</td>
                                                        <td>{{ eventData.employees_assigned }}</td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-secondary text-white">Hotel</td>
                                                        <td>
                                                            <li v-for="(hotel, index) in eventData.hotel" :key="index">
                                                                {{ hotel.name }}
                                                                <b-badge pill :variant="`light-success`" class="text-capitalize"> {{ hotel.numberOfStaff }} Employee </b-badge>
                                                            </li>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-secondary text-white">Vehicle</td>

                                                        <td>
                                                            <li v-for="vehicle in eventData.vehicle" :key="vehicle">{{ vehicle }}</li>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-secondary text-white">Notes</td>
                                                        <td>{{ eventData.notes }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </b-row>
                                    </b-card-body>
                                </b-tab>

                                <b-tab title="Files" v-if="$Can('event_files_tab')">
                                    <b-card-body class="mt-0 pt-0">
                                        <b-row>
                                            <b-button v-show="$Can('event_file_add_btn')" class="btn-icon ml-auto  mt-1 mb-1" @click="addNewFile()" variant="primary">
                                                Add New File
                                            </b-button>

                                            <table class=" table  table-bordered table-hover table-sm ">
                                                <b-col cols="12" v-if="eventData.id != null">
                                                    <!-- user suggestion  -->
                                                    <div v-for="(data, index) in files" :key="index" class="ml-5 mr-5 mt-2 mb-2">
                                                        <div v-if="data.status == 'A'" class="d-flex justify-content-between align-items-center">
                                                            <b-img src="@/assets/images/file.png" @click="fileEdit(data)" class="mr-50 " style="cursor:pointer" width="30px" />
                                                            <div class="user-page-info pr-1" style="width: 100%">
                                                                <span @click="fileEdit(data)" style="cursor:pointer; font-size:1.1em" class="mb-2">{{ data.fileName }} </span>
                                                            </div>

                                                            <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" @click="invoiceFile(data)" class="btn-icon ml-auto mr-1" style="padding: 1% 1%;" size="sm">
                          Invoice
                        </b-button> -->

                                                            <b-button
                                                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                                variant="primary"
                                                                target="_blank"
                                                                :href="fileURL + data.fileLink"
                                                                style="cursor:pointer;    margin-right:2px"
                                                                class="btn-icon ml-auto mr-1"
                                                                size="sm"
                                                            >
                                                                <feather-icon icon="DownloadIcon" />
                                                            </b-button>

                                                            <b-button
                                                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                                variant="danger"
                                                                @click="deleteFile(data)"
                                                                class="btn-icon ml-auto mr-1"
                                                                style=""
                                                                size="sm"
                                                            >
                                                                <feather-icon icon="XIcon" />
                                                            </b-button>
                                                        </div>

                                                        <div v-else class="d-flex justify-content-between align-items-center">
                                                            <b-img src="@/assets/images/url.png" @click="linkEdit(data)" class="mr-50 " style="cursor:pointer" width="30px" />
                                                            <div class="user-page-info pr-1" style="width: 100%">
                                                                <span @click="linkEdit(data)" style="cursor:pointer; font-size:1.1em">{{ data.fileName }} </span>
                                                            </div>
                                                            <b-button
                                                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                                variant="primary"
                                                                target="_blank"
                                                                :href="data.fileLink"
                                                                style="cursor:alias;  margin-right:2px"
                                                                class="btn-icon ml-auto mr-1"
                                                                size="sm"
                                                                v-b-tooltip.hover
                                                                title="Go to url adress"
                                                            >
                                                                <feather-icon icon="ExternalLinkIcon" />
                                                            </b-button>

                                                            <b-button
                                                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                                variant="danger"
                                                                @click="deleteFile(data)"
                                                                class="btn-icon ml-auto mr-1"
                                                                style=""
                                                                size="sm"
                                                            >
                                                                <feather-icon icon="XIcon" />
                                                            </b-button>
                                                        </div>
                                                        <hr />
                                                    </div>

                                                    <div v-if="files.length == 0" class="d-flex justify-content-center align-items-center mt-1 mb-1">
                                                        No Files
                                                    </div>
                                                </b-col>
                                            </table>
                                        </b-row>
                                    </b-card-body>
                                </b-tab>

                                <b-tab title="Team" v-if="$Can('event_team_tab')">
                                    <b-card-body class="mt-0 pt-0">
                                        <b-row>
                                            <table class="table table-bordered">
                                                <b-tabs
                                                    vertical
                                                    nav-wrapper-class="nav-vertical mt-1 ml-2"
                                                    class="tab-class"
                                                    pills
                                                    active-nav-item-class="text-white text-left"
                                                    style="text-align:left"
                                                >
                                                    <b-tab v-for="(data, index) in eventData.teamList" :key="index">
                                                        <template #title>
                                                            <span v-if="data.role == 'Foreman'" class="mr-1">🤵🏻</span>
                                                            <span v-else class="mr-1">🧍🏻</span>

                                                            <span class="text-left">{{ data.employeeName }}</span>
                                                        </template>
                                                        <b-row class="m-1">
                                                            <table class="table table-bordered table-hover table-sm w-100">
                                                                <tr>
                                                                    <td class="bg-secondary text-white">
                                                                        Name
                                                                    </td>

                                                                    <td>{{ data.employeeName }}</td>

                                                                    <td rowspan="4" style="width:170px; vertical-align:top">
                                                                        <b-img-lazy
                                                                            rounded
                                                                            thumbnail
                                                                            fluid
                                                                            v-bind="mainProps"
                                                                            class="d-inline-block mr-1"
                                                                            style="width: 150px"
                                                                            ref="refPreviewEl"
                                                                            :src="teamProfileURL + data.teamProfil"
                                                                        />
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td class="bg-secondary text-white">
                                                                        Role
                                                                    </td>

                                                                    <td>{{ data.role }}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td class="bg-secondary text-white">
                                                                        Day Rate
                                                                    </td>

                                                                    <td>{{ $func.formatPrice(data.rate_client ? data.rate_client : 0, eventData.currency) }}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td class="bg-secondary text-white">
                                                                        Status
                                                                    </td>

                                                                    <td>
                                                                        <b-badge v-if="data.status == 'A'" pill :variant="`light-success`" class="text-capitalize">
                                                                            Not Worked
                                                                        </b-badge>

                                                                        <b-badge v-if="data.status == 'S'" pill :variant="`light-warning`" class="text-capitalize">
                                                                            Sick
                                                                        </b-badge>

                                                                        <b-badge v-if="data.status == 'H'" pill :variant="`info`" class="text-capitalize">
                                                                            Holiday
                                                                        </b-badge>

                                                                        <b-badge v-if="data.status == 'E'" pill :variant="`primary`" class="text-capitalize">
                                                                            Worked
                                                                        </b-badge>

                                                                        <b-badge v-if="data.status == 'W'" pill :variant="`danger`" class="text-capitalize">
                                                                            AWOL
                                                                        </b-badge>

                                                                        <b-badge v-if="data.status == 'O'" pill :variant="`light-primary`" class="text-capitalize">
                                                                            Worked - Overtime
                                                                        </b-badge>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td class="bg-secondary text-white">
                                                                        Overtime Hours
                                                                    </td>

                                                                    <td>
                                                                        <span>{{ data.overtime_hours }} Hours</span> -
                                                                        <span
                                                                            >{{
                                                                                $func.formatPrice(
                                                                                    data.overtime_hours * data.overtime_client ? data.overtime_hours * data.overtime_client : 0,
                                                                                    eventData.currency
                                                                                )
                                                                            }}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr v-show="$Can('employees_availabilty_function')">
                                                                    <td class="bg-secondary text-white">Change Status</td>
                                                                    <td colspan="2">
                                                                        <b-row class="d-xl-flex justify-content-xl-between mr-1 ml-1 mt-1">
                                                                            <b-button
                                                                                :disabled="data.status == 'O'"
                                                                                @click="addOvertime(data)"
                                                                                class="col-xl-2 col-lg-6 col-md-12 col-xs-12 col-sm-12  mb-1"
                                                                                variant="primary"
                                                                            >
                                                                                Overtime
                                                                            </b-button>

                                                                            <b-button
                                                                                :disabled="data.status == 'S'"
                                                                                @click="availabilityAdd('S', data.id)"
                                                                                class="col-xl-2 col-lg-6 col-md-12 col-xs-12 col-sm-12  mb-1"
                                                                                variant="warning"
                                                                            >
                                                                                Sick
                                                                            </b-button>

                                                                            <b-button
                                                                                :disabled="data.status == 'H'"
                                                                                @click="availabilityAdd('H', data.id)"
                                                                                class="col-xl-2 col-lg-6 col-md-12 col-xs-12 col-sm-12  mb-1"
                                                                                variant="info"
                                                                            >
                                                                                Holiday
                                                                            </b-button>

                                                                            <b-button
                                                                                :disabled="data.status == 'W'"
                                                                                @click="availabilityAdd('W', data.id)"
                                                                                class="col-xl-2 col-lg-6 col-md-12 col-xs-12 col-sm-12  mb-1"
                                                                                variant="danger"
                                                                            >
                                                                                Awol
                                                                            </b-button>

                                                                            <b-button
                                                                                :disabled="!(data.status != 'E')"
                                                                                @click="availabilityAdd('R', data.id)"
                                                                                class="col-xl-2 col-lg-6 col-md-12 col-xs-12 col-sm-12  mb-1"
                                                                                variant="success"
                                                                            >
                                                                                Reset Status
                                                                            </b-button>
                                                                        </b-row>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </b-row>
                                                    </b-tab>
                                                </b-tabs>
                                            </table>
                                            <!-- <b-card v-for="(data, index) in eventData.teamList" :key="index">
                        {{ data }}
                      </b-card> -->
                                        </b-row>
                                    </b-card-body>
                                </b-tab>

                                <b-tab title="Cost Table" v-if="$Can('event_cost_tab')">
                                    <b-card-body class="mt-0 pt-0">
                                        <b-row>
                                            <table class=" table  table-bordered table-hover table-sm ">
                                                <b-row class="mt-1 ml-1">
                                                    <b-col cols="12" sm="12" md="12" xl="2">
                                                        <b-form-group>
                                                            <label for="tax_due">Cost Date</label>
                                                            <flat-pickr
                                                                id="tax_due"
                                                                class="form-control"
                                                                :config="{ dateFormat: 'd/m/Y', locale: { firstDayOfWeek: 1 } }"
                                                                placeholder="DD/MM/YYYY"
                                                                v-model="costData.date"
                                                            />
                                                        </b-form-group>
                                                    </b-col>

                                                    <b-col cols="12" sm="12" md="12" xl="5">
                                                        <b-form-group label="Cost Description" label-for="Description">
                                                            <b-form-input v-model="costData.description" id="Description" type="text"></b-form-input>
                                                        </b-form-group>
                                                    </b-col>

                                                    <b-col cols="12" sm="6" md="4" xl="2">
                                                        <b-form-group label="Currency" label-for="currency">
                                                            <v-select v-model="costData.currency" :options="currencies" :reduce="(val) => val.id" label="code" :clearable="false" input-id="currency" />
                                                        </b-form-group>
                                                    </b-col>

                                                    <b-col cols="12" sm="12" md="12" xl="2">
                                                        <b-form-group label="Price" label-for="fault">
                                                            <b-form-input v-model="costData.cost" id="fault" type="text"></b-form-input>
                                                        </b-form-group>
                                                    </b-col>

                                                    <b-col cols="12" sm="12" md="12" xl="1">
                                                        <b-button
                                                            v-show="$Can('event_cost_add_btn')"
                                                            @click="addCost"
                                                            :disabled="
                                                                !(
                                                                    costData.date != null &&
                                                                    costData.date != '' &&
                                                                    costData.description != null &&
                                                                    costData.description != '' &&
                                                                    costData.cost != null &&
                                                                    costData.cost != '' &&
                                                                    costData.currency != null &&
                                                                    costData.currency != ''
                                                                )
                                                            "
                                                            type="add"
                                                            variant="primary"
                                                            class="mr-1 mt-2 float-right"
                                                        >
                                                            Add
                                                        </b-button>
                                                    </b-col>
                                                </b-row>

                                                <b-row class="m-2">
                                                    <b-table hover :small="true" :items="cost_list" head-variant="dark" :fields="costColumns" show-empty empty-text="No matching records found">
                                                        <template #cell(description)="data">
                                                            <span v-if="data.value == 'HOTEL'"> {{ data.value }} - {{ data.item.hotelName }}</span>
                                                            <span v-else> {{ data.value }} </span>
                                                        </template>

                                                        <template #cell(currency)="data">
                                                            {{ $func.getCurrencyCode(data.value) }}
                                                        </template>

                                                        <template #cell(cost)="data">
                                                            {{ $func.formatPrice(data.value, data.item.currency) }}
                                                        </template>

                                                        <template #cell(actions)="data">
                                                            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                                                                <template #button-content>
                                                                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                                                </template>
                                                                <!-- <b-dropdown-item @click="addToInvoice(data.item)">
                                <feather-icon icon="FileTextIcon" />
                                <span class="align-middle ml-50">Add to invoice</span>
                              </b-dropdown-item> -->

                                                                <b-dropdown-item @click="removeCost(data.item)">
                                                                    <feather-icon icon="XIcon" />
                                                                    <span class="align-middle ml-50">Delete</span>
                                                                </b-dropdown-item>
                                                            </b-dropdown>
                                                        </template>
                                                    </b-table>
                                                </b-row>
                                            </table>
                                        </b-row>
                                    </b-card-body>
                                </b-tab>

                                <!-- <b-tab title="Invoice">
                <b-card-body class="mt-0 pt-0">
                  <invoice v-if="!formShow" :eventData.sync="eventData"> </invoice>
                </b-card-body>
              </b-tab> -->

                                <b-tab title="Timeline" v-if="$Can('event_timeline_tab')">
                                    <b-card v-if="eventData.timeline ? eventData.timeline.length : 0 > 0" style="overflow-y:scroll; height:500px">
                                        <app-timeline class="mt-2">
                                            <app-timeline-item v-for="item in eventData.timeline" :key="item.id" :variant="$func.varyant(item.status)" :icon="$func.varicon(item.status)">
                                                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                                    <h6 v-html="item.title"></h6>
                                                    <small class="text-muted">{{ $func.dateFormatTimeline(item.created_at) }}</small>
                                                </div>
                                                <small>{{ 'By ' + item.userNameSurname }} - {{ item.ipAddress }}</small>
                                            </app-timeline-item>
                                        </app-timeline>
                                    </b-card>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </b-col>

                    <b-col cols="12" v-show="$Can('event_total_event_list')">
                        <b-card>
                            <b-row>
                                <div class="text-center bg-dark text-white w-100">
                                    <span class="text-center text-white m-2" style="font-size:1.2em">Total Event Costs</span>
                                </div>
                                <b-table
                                    striped
                                    hover
                                    class="position-relative m-2"
                                    :items="relatedCosts"
                                    filter-debounce="250"
                                    responsive
                                    :small="true"
                                    head-variant="dark"
                                    :fields="totalColumns"
                                    show-empty
                                    empty-text="No matching records found"
                                >
                                    <template #cell(date)="data">
                                        {{ data.value }}
                                    </template>
                                    <template #cell(description)="data">
                                        {{ data.value }}
                                    </template>

                                    <template #cell(currency)="data">
                                        {{ $func.getCurrencyCode(data.value) }}
                                    </template>

                                    <template #cell(cost)="data">
                                        {{ $func.formatPrice(data.value, eventData.currency) }}
                                    </template>
                                </b-table>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-col>

                <b-col cols="2" v-if="events.length > 0">
                    <b-col cols="12" class="d-none d-xl-block">
                        <b-card>
                            <p class="text-center mb-1"><strong>Event Dates </strong></p>
                            <hr />

                            <div v-for="event in events" :key="event.id">
                                <b-badge variant="dark" class="p-1 mb-1 d-block" style="cursor:pointer" block @click="viewevent(event.id)">
                                    <span style="font-size:1.2em">{{ dateFormat(event.date) }} </span>
                                </b-badge>
                                <hr />
                            </div>
                        </b-card>
                    </b-col>
                </b-col>
            </b-row>

            <b-modal
                id="modal-file"
                cancel-variant="outline-secondary"
                ok-title="Save File"
                @ok="handleOk"
                @show="resetModal"
                @hidden="resetModal"
                cancel-title="Close"
                :no-close-on-backdrop="true"
                centered
                title="Add New File"
            >
                <b-form ref="fileRules">
                    <b-form-group :state="nameState" invalid-feedback="File Name is required">
                        <label for="fileName">File Name:</label>
                        <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox class="custom-control-primary" name="check-button" switch inline v-model="selectFileType">
                            <span class="switch-icon-left">
                                <feather-icon icon="FileIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="LinkIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group :state="fileState" invalid-feedback="File is required" v-if="selectFileType">
                        <b-form-file
                            @input="fileSelected"
                            type="file"
                            :state="fileState"
                            required
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            class="animate__animated animate__fadeIn"
                        />
                    </b-form-group>
                    <b-form-group :state="linkState" invalid-feedback="Link is required" v-else>
                        <b-form-input :state="linkState" id="basicInput" v-model="fileLink" required placeholder="Enter Link" class="animate__animated animate__fadeIn" />
                    </b-form-group>
                </b-form>
                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                    <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                </b-col>
            </b-modal>

            <b-modal id="modal-file-update" cancel-variant="outline-secondary" ok-title="Change" @ok="handleUpdate" cancel-title="Close" :no-close-on-backdrop="true" centered title="Change File Name">
                <b-form ref="fileRules">
                    <b-form-group :state="nameState" invalid-feedback="File Name is required">
                        <label for="fileName">File Name:</label>
                        <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
                    </b-form-group>
                </b-form>
                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                    <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                </b-col>
            </b-modal>

            <b-modal id="modal-overtime" cancel-variant="outline-secondary" ok-title="Save" @ok="handleUpdateOvertime" cancel-title="Close" :no-close-on-backdrop="true" centered title="Overtime">
                <b-form ref="overtimeRules">
                    <b-form-group :state="overtimeState" invalid-feedback="Overtime(hours) is required">
                        <label for="Overtime">Overtime(hours):</label>
                        <b-form-input id="Overtime" type="text" :state="overtimeState" v-model="modalOvertime" required />
                    </b-form-group>
                </b-form>
            </b-modal>

            <b-modal
                id="modal-duplicate"
                cancel-variant="outline-secondary"
                ok-title="Save"
                @ok="handleUpdateDuplicate"
                cancel-title="Close"
                :no-close-on-backdrop="true"
                centered
                title="Duplicate Event"
            >
                <b-form ref="duplicateRules">
                    <b-form-group :state="duplicateState" invalid-feedback="Date field is required" label="Date*" label-for="date">
                        <flat-pickr
                            id="date"
                            :state="duplicateState"
                            input-id="date"
                            v-model="duplicateData.date"
                            class="form-control"
                            :config="{ mode: 'multiple', locale: { firstDayOfWeek: 1 }, dateFormat: 'd/m/Y', disable: disabledDates }"
                        />
                    </b-form-group>
                </b-form>
            </b-modal>
        </b-overlay>
    </section>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
("bootstrap-vue");
import { BModal, BAvatar, BCard, BCardBody, BRow, BCol, BImg, BLink, BButton, BAlert, BCardHeader, BCardTitle, VBToggle, BTabs, BTab, BCardText, BProgress } from "bootstrap-vue";
import moment from "moment";
import eventStoreModule from "../eventStoreModule";
import invoice from "./Invoice.vue";

import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import ProfileCropper from "@core/components/image-cropper/ProfileCropper.vue";
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

// import useCurrenciesList from '../useCurrenciesList';
// import { avatarText } from '@core/utils/filter'

export default {
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },

    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BButton,
        BAvatar,
        BModal,
        BCardBody,
        BImg,
        BCardHeader,
        BCardTitle,
        BTabs,
        BCardText,
        BTab,
        ProfileCropper,
        BProgress,
        flatPickr,
        vSelect,
        invoice,

        AppTimeline,
        AppTimelineItem,
    },
    setup() {
        const event_APP_STORE_MODULE_NAME = "event";

        // Register module
        if (!store.hasModule(event_APP_STORE_MODULE_NAME)) store.registerModule(event_APP_STORE_MODULE_NAME, eventStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(event_APP_STORE_MODULE_NAME)) store.unregisterModule(event_APP_STORE_MODULE_NAME);
        });
    },

    data: function() {
        var eventData = {
            name: null,
            contact_person: null,
            email: null,
            phone: null,
            address1: null,
            city: null,
            vat_number: null,
            colour: "",
            textColour: "",
            password: null,
            profile: null,
            cost_list: null,
            currency: null,
        };

        var duplicateData = {
            id: null,
            date: null,
        };

        const statusOptions = [
            { label: "Active", value: "active" },
            { label: "Passive", value: "passive" },
            { label: "Delete", value: "deleted" },
        ];

        return {
            createInvoice: true,
            eventData: eventData,
            duplicateData: duplicateData,
            baseURL: store.state.app.baseURL,
            imageUrl: store.state.app.profileURL + "none.jpg",
            tempImage: null,
            profileURL: store.state.app.profileURL,
            teamProfileURL: store.state.app.profileURL,
            selectedImage: null,
            croppedImage: null,
            userData: {},
            percentCompleted: 0,
            roleOptions: [],
            statusOptions,
            eventId: null,
            formShow: false,
            permissions: [],
            events: [],
            cost_list: [],
            relatedCosts: [],
            availability: "A",

            startDate: null,
            endDate: null,
            rangeDate: null,

            disabledDates: [],

            fetchAvailability: [],

            //FILES
            where: router.currentRoute.name,
            files: [],
            selectFileType: true,
            fileLink: null,
            modalId: null,
            modalFileName: null,
            modalFile: null,
            download: false,
            fileURL: store.state.app.fileURL,
            modalOvertime: null,
            modalOvertimeStaff: null,
            currencies: [],
            percentCompleted: 0,

            fetchTeams: [],
            totalRows: 0,

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },

            costData: { description: null, date: null, cost: null, currency: eventData.currency },

            config: {
                dateFormat: "d-m-Y",
                mode: "range",
            },

            mainProps: {
                blank: true,
                blankColor: "#777",
                width: 75,
                height: 75,
                class: "m1",
            },

            tableColumns: [
                { key: "availability", sortable: true, class: "text-center" },
                { key: "startDate", sortable: true, class: "text-center" },
                { key: "endDate", sortable: true, class: "text-center" },
            ],

            teamTableColumns: [
                { key: "id", sortable: true, class: "text-center" },
                { key: "title", sortable: true, class: "text-center" },
                { key: "foreman", sortable: true, class: "text-center" },
                { key: "staff_count", sortable: true, class: "text-center" },
                { key: "day_rate", sortable: true, class: "text-center" },
                { key: "status", sortable: true, class: "text-center" },
            ],

            costColumns: [
                { key: "description", sortable: false, class: "text-center" },
                { key: "currency", sortable: false, class: "text-center" },
                { key: "cost", sortable: false, class: "text-center" },
                { key: "actions", sortable: false, class: "text-center" },
            ],

            totalColumns: [
                { key: "date", sortable: false, class: "text-center" },
                { key: "description", sortable: false, class: "text-center" },
                { key: "currency", sortable: false, class: "text-center" },
                { key: "cost", sortable: false, class: "text-center" },
            ],

            // currencies: useCurrenciesList,
        };
    },

    created() {
        this.getEventById();
    },

    methods: {
        addCost() {
            // this.faultData.user = (this.userData.name ? this.userData.name : '') + ' ' + (this.userData.surname ? this.userData.surname : '');

            this.cost_list.push(this.costData);
            this.relatedCosts.push(this.costData);

            this.eventData.cost_list.push(this.costData);

            this.costData = { description: null, date: null, cost: null, currency: this.eventData.currency };
            store
                .dispatch("event/updateCost", { id: this.eventData.id, cost_list: this.cost_list })
                .then((response) => {
                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;
                });
        },

        removeCost(cost) {
            this.cost_list = this.cost_list.filter((item) => item.description !== cost.description);
            this.eventData.cost_list = this.eventData.cost_list.filter((item) => item.description !== cost.description);

            store
                .dispatch("event/updateCost", { id: this.eventData.id, cost_list: this.cost_list })
                .then((response) => {
                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;
                });
        },

        addToInvoice(invoiceItem) {
            let index = this.cost_list.findIndex((obj) => obj.description === invoiceItem.description);

            this.cost_list[index].invoice = 1;
            let indexEvent = this.eventData.cost_list.findIndex((obj) => obj.description === invoiceItem.description);
            this.eventData.cost_list[indexEvent].invoice = 1;

            store
                .dispatch("event/updateCost", { id: this.eventData.id, cost_list: this.cost_list })
                .then((response) => {
                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;
                });
        },

        editEvent() {
            router.push({ name: "event-edit", params: { id: router.currentRoute.params.id } });
        },

        deleteEvent() {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, remove the event!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.formShow = true;

                    axiosIns
                        .delete(`deleteEvent/${this.eventData.id}`)
                        .then((res) => {
                            router.push({ name: "event-list" });
                            this.formShow = false;
                        })
                        .catch((error) => {
                            console.log("error delete ", error);
                        });
                }
            });
        },

        viewevent(id) {
            this.formShow = true;
            store
                .dispatch("event/fetchEvent", { id: id })
                .then((response) => {
                    this.relatedCosts = [];
                    this.eventData = response.data;

                    this.files = response.data.get_files;
                    this.events = response.data.relatedEvents;
                    this.costData.currency = response.data.currency;
                    this.imageUrl = this.profileURL + response.data.client_logo;
                    this.cost_list = [];

                    response.data.relatedEvents.forEach((element) => {
                        this.disabledDates.push(this.dateFormat(element.date));
                    });

                    response.data.cost_list.forEach((element) => {
                        this.cost_list.push(element);
                    });

                    this.events.forEach((element) => {
                        element.cost_list.forEach((cost) => {
                            this.relatedCosts.push(cost);
                        });
                    });

                    this.events = this.events.filter((item) => item.id != this.eventData.id);

                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;
                });
            router.push({ name: "event-view", params: { id: id } });
        },

        activateEvent() {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "Yes, activate the event!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    var tempData = {
                        status: "A",
                        userId: router.currentRoute.params.id,
                    };
                    this.eventData.status = "active";

                    store.dispatch("event/userStatus", tempData).then((response) => {
                        if (response.status == 200) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Successful",
                                    text: "✔️ User Update Successful",
                                    icon: "ThumbsUpIcon",
                                    variant: "success",
                                },
                            });
                        }
                    });
                }
            });
        },

        terminateEvent() {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Yes, terminate the event!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    var tempData = {
                        status: "P",
                        userId: router.currentRoute.params.id,
                    };
                    this.eventData.status = "passive";

                    store.dispatch("event/userStatus", tempData).then((response) => {
                        if (response.status == 200) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Successful",
                                    text: "✔️ User Update Successful",
                                    icon: "ThumbsUpIcon",
                                    variant: "success",
                                },
                            });
                        }
                    });
                }
            });
        },

        removeEvent() {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete the event!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    var tempData = {
                        status: "D",
                        userId: router.currentRoute.params.id,
                    };

                    this.eventData.status = "deleted";

                    store.dispatch("event/userStatus", tempData).then((response) => {
                        if (response.status == 200) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Successful",
                                    text: "✔️ User Update Successful",
                                    icon: "ThumbsUpIcon",
                                    variant: "success",
                                },
                            });
                        }
                    });
                }
            });
        },

        availabilityAdd(val, userId) {
            var tempData = {
                date: null,
                availability: null,
                userId: null,
                event_id: null,
            };

            tempData.availability = val;
            tempData.userId = userId;
            tempData.event_id = this.eventData.id;
            let dateArray = [];
            dateArray.push(this.eventData.date);
            tempData.date = dateArray;

            store.dispatch("event/employeeAvailability", tempData).then((response) => {
                if (response.status == 200) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Successful",
                            text: "✔️ Employee Status Change Successful",
                            icon: "ThumbsUpIcon",
                            variant: "success",
                        },
                    });

                    this.getEventById();
                }
            });
        },

        getEventById() {
            this.formShow = true;

            if (router.currentRoute.params.id) {
                this.eventId = parseInt(router.currentRoute.params.id);

                store
                    .dispatch("event/fetchEvent", { id: router.currentRoute.params.id })
                    .then((response) => {
                        if (response != "Error") {
                            this.relatedCosts = [];
                            this.cost_list = [];

                            this.eventData = response.data;
                            this.files = response.data.get_files;
                            this.events = response.data.relatedEvents;
                            this.imageUrl = this.profileURL + response.data.client_logo;
                            this.currencies = this.$func.getCurrencies();

                            response.data.relatedEvents.forEach((element) => {
                                this.disabledDates.push(this.dateFormat(element.date));
                            });

                            response.data.cost_list.forEach((element) => {
                                this.cost_list.push(element);
                            });

                            this.events.forEach((element) => {
                                element.cost_list.forEach((cost) => {
                                    this.relatedCosts.push(cost);
                                });
                            });

                            this.events = this.events.filter((item) => item.id != this.eventData.id);

                            this.formShow = false;
                        } else {
                            router.push({ name: "event-list" });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.formShow = false;
                    });
            } else {
                this.formShow = false;
            }
        },

        dateFormat(val) {
            moment.locale("en-US");

            return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
        },

        // formatPrice(value, val) {
        //   if (value != null) {
        //     let val = (value / 1).toFixed(2).replace(',', '.');
        //     return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        //   }
        // },

        onTeamRowSelected(item) {
            router.push({ name: "team-edit", params: { id: item[0].id } });
        },

        fileEdit(data) {
            this.modalId = data.id;
            this.modalFileName = data.fileName;
            this.$bvModal.show("modal-file-update");
        },

        linkEdit(data) {
            this.modalId = data.id;
            this.modalFileName = data.fileName;
            this.$bvModal.show("modal-file-update");
        },

        invoiceFile(data) {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Approve",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.formShow = true;

                    axiosIns
                        .put(`invoiceEventFile`, data)
                        .then((res) => {
                            this.formShow = false;
                        })
                        .catch((error) => {
                            console.log("error ", error);
                        });
                }
            });
        },

        deleteFile(data) {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.formShow = true;

                    axiosIns
                        .delete(`deleteEventFile/${data.id}`)
                        .then((res) => {
                            var tempList = [];
                            this.files.forEach((element) => {
                                if (element.id != data.id) {
                                    tempList.push(element);
                                }
                            });

                            this.files = tempList;
                            this.formShow = false;
                        })
                        .catch((error) => {
                            console.log("error delete ", error);
                        });
                }
            });
        },

        addNewFile() {
            this.$bvModal.show("modal-file");
        },

        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();

            if (this.selectFileType) {
                if (this.percentCompleted == 100) {
                    var data = {
                        event_id: this.eventData.id,
                        fileName: this.modalFileName,
                        fileLink: this.modalFile,
                        status: "A",
                    };

                    // Trigger submit handler
                    this.modalOkFile(data);
                }
            } else {
                var data = {
                    event_id: this.eventData.id,
                    fileName: this.modalFileName,
                    fileLink: this.fileLink,
                    status: "B",
                };
                this.modalOkFile(data);
            }
        },

        handleUpdate(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.modalUpdate();
        },

        resetModal() {
            this.modalFileName = null;
            this.modalFile = null;
        },

        modalOkFile(data) {
            if (!this.checkFormValidity()) {
                return;
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-file");
            });

            this.formShow = true;
            axiosIns
                .post("saveEventFile", data)
                .then((response) => {
                    this.files.push({
                        id: response.data.id,
                        fileName: response.data.fileName,
                        fileLink: response.data.fileLink,
                        status: response.data.status,
                    });

                    this.selectFileType = true;
                    this.fileLink = null;
                    this.modalFileName = null;
                    this.modalFile = null;

                    this.formShow = false;
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        modalUpdate(val) {
            if (!this.checkFormValidity()) {
                return;
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-file-update");
            });

            this.formShow = true;

            var data = {
                id: this.modalId,
                fileName: this.modalFileName,
                fileLink: this.modalFile,
            };

            axiosIns
                .put("updateEventFile", data)
                .then((response) => {
                    this.modalFileName = null;
                    this.modalFile = null;

                    this.files.forEach((file) => {
                        if (file.id == this.modalId) {
                            file.fileName = response.data.fileName;
                        }
                    });

                    this.formShow = false;
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        fileSelected(event) {
            //  this.formShow = true;
            let formData = new FormData();
            formData.append("file", event);

            const config = {
                onUploadProgress: function(progressEvent) {
                    this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                }.bind(this),
            };

            axiosIns
                .post("uploadEventFile", formData, config)
                .then((response) => {
                    this.modalFile = response.data;

                    // this.tempImage = null;
                    // this.selectedImage = null;
                    this.formShow = false;
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        checkFormValidity() {
            const nameValid = this.$refs.fileRules.checkValidity();
            const fileValid = this.$refs.fileRules.checkValidity();

            return fileValid && nameValid;
        },

        imageUpload() {},

        imageCropped(croppedImage) {
            if (croppedImage != null) {
                this.formShow = true;
                let formData = new FormData();
                formData.append("id", this.eventData.id);
                formData.append("file", croppedImage);
                const config = {
                    onUploadProgress: function(progressEvent) {
                        this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    }.bind(this),
                };
                axiosIns
                    .post("uploadPreviewPhoto", formData, config)
                    .then((response) => {
                        this.eventData.profile = response.data;
                        this.imageUrl = this.profileURL + response.data;

                        this.tempImage = null;
                        this.selectedImage = null;
                        this.formShow = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Upload Successful",
                                text: "✔️ Image Cropped Successfully",
                                icon: "ThumbsUpIcon",
                                variant: "success",
                            },
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.formShow = false;

                        this.$swal({
                            title: "Error",
                            text: "⛔ Image Upload Not Successful. Please try again or contact support.",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });
                    });
            } else {
                this.tempImage = null;
                this.selectedImage = null;
            }
        },

        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        addOvertime(val) {
            this.modalOvertimeStaff = val.id;
            this.modalOvertime = val.overtime_hours;

            this.$bvModal.show("modal-overtime");
        },

        handleUpdateOvertime(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.modalUpdateOvertime();
        },

        modalUpdateOvertime(val) {
            if (!this.checkFormValidityOvertime()) {
                return;
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-overtime");
            });

            this.formShow = true;

            var data = {
                id: this.eventData.id,
                staffId: this.modalOvertimeStaff,
                overtime_hours: this.modalOvertime,
            };

            axiosIns
                .post("overtimeStaff", data)
                .then((response) => {
                    this.modalOvertimeStaff = null;
                    this.modalOvertime = null;

                    this.getEventById();
                    this.cost_list = [];
                    this.relatedCosts = [];

                    this.formShow = false;
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        checkFormValidityOvertime() {
            const overtimeValid = this.$refs.overtimeRules.checkValidity();

            return overtimeValid;
        },

        duplicateEvent() {
            this.duplicateData.id = this.eventData.id;

            this.$bvModal.show("modal-duplicate");
        },

        handleUpdateDuplicate(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.modalUpdateDuplicate();
        },

        modalUpdateDuplicate(val) {
            if (!this.checkFormValidityDuplicate()) {
                return;
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-duplicate");
            });

            this.formShow = true;

            var data = {
                id: this.eventData.id,
                date: this.duplicateData.date,
            };

            axiosIns
                .post("duplicateEvent", data)
                .then((response) => {
                    this.getEventById();
                    this.formShow = false;
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        checkFormValidityDuplicate() {
            const duplicateValid = this.$refs.duplicateRules.checkValidity();

            return duplicateValid;
        },
    },

    computed: {
        nameState() {
            return this.modalFileName != null && this.modalFileName != "" ? true : false;
        },

        fileState() {
            return this.modalFile != null && this.modalFile != "" ? true : false;
        },
        linkState() {
            let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            let regex = new RegExp(expression);
            let link = this.fileLink;
            if (link != null) {
                if (link.match(regex)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },

        overtimeState() {
            return this.modalOvertime != null && this.modalOvertime != "" ? true : false;
        },

        duplicateState() {
            return this.duplicateData.date != null && this.duplicateData.date != "" ? true : false;
        },
    },

    // setup() {
    //   const refInputEl = ref(null);
    //   const previewEl = ref(null);
    //   const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
    //   return {
    //     refInputEl,
    //     previewEl,
    //     inputImageRenderer,
    //   };
    // },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.icon {
    stroke: black;
    fill: none;
    width: 36px;
    height: 36px;
    stroke-width: 0.6666666666666666;
}

.vue-pdf-embed > div {
    margin-bottom: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>

<style scoped>
.tab-class >>> .nav-item {
    position: relative;
    display: flex;
    justify-content: start;
}

.tab-class >>> .nav-vertical {
    padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
    width: 100%;
}
</style>
